<template>
    <b-modal :id="$route.name + 'CreateModal'" title="Добавить страну" size="md" hide-footer no-enforce-focus @show="opened">
        <b-row>
            <b-col cols="12">
                <b-row>
                    <b-col cols="6">
                        <label>Город</label>
                        <b-input v-model="country.name_ru" placeholder="Таджикистан" />
                    </b-col>
                    <b-col cols="6">
                        <label>Город на анлийсском языке</label>
                        <b-input v-model="country.name" placeholder="Tajikistan" />
                    </b-col>
                    <b-col cols="6">
                        <label>Код</label>
                        <b-input class="mt-2" v-model="country.iso2" placeholder="tj" />
                    </b-col>
                </b-row>
                <b-row class="justify-content-between">
                    <b-button
                    :disabled="submitButtonDisabled"
                    @click="createCountry"
                    class="mt-2 mx-1"
                    variant="primary">Добавить</b-button>
                    <b-button @click="btnCancel()" class="mt-2 mx-1" variant="secondary">Отмена</b-button>
                </b-row>
            </b-col>
        </b-row>
    </b-modal>
</template>
<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    components: {
        ToastificationContent,
    },
    data(){
        return {
            country: {
                name: undefined,
                iso2: undefined,
            },
            submitButtonDisabled: false,
        }
    },
    mounted(){
    },
    methods: {
        createCountry(){
            this.submitButtonDisabled = true
            this.$http
                .post('addresses/countries', this.country)
                .then(res => {
                    this.btnCancel()
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Успех!',
                        icon: 'CheckIcon',
                        variant: 'success',
                        text: `Страна успешно добавлена!`,
                        },
                    })
                    this.$emit('refresh')
                    this.$bvModal.hide(this.$route.name + 'CreateModal')
                    this.clearData()
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err.response.data.errors,
                        },
                    })
                })
                .finally(()=>{
                    this.submitButtonDisabled = false
                })
        },
        opened(){
            this.clearData()
            // this.country = {
            //     name: undefined,
            //     iso2: undefined,
            // }
        },
        clearData(){
            this.country = {
                name_ru: '',
                name: '',
                iso2: ''
            }
        },
        btnCancel(){
            this.$bvModal.hide(this.$route.name + 'CreateModal')
            this.clearData()
        }
    }
}
</script>