<template>
    <b-modal id="countryModalEdit" title="Изменить данные страны" size="md" hide-footer no-enforce-focus @show="opened">
        <b-row>
            <b-col cols="12">
                <b-row>
                    <b-col cols="6">
                        <label>Город</label>
                        <b-input v-model="country.name_ru" placeholder="Таджикистан" />
                    </b-col>
                    <b-col cols="6">
                        <label>Город</label>
                        <b-input v-model="country.name" placeholder="Tajikistan" />
                    </b-col>
                    <b-col cols="6">
                        <label>Код</label>
                        <b-input class="mt-2" v-model="country.iso2" placeholder="tj" />
                    </b-col> 
                </b-row>
                <b-row class="justify-content-between">
                    <b-button
                    :disabled="submitButtonDisabled"
                    @click="editCountry()"
                    class="mt-2 mx-1"
                    variant="primary">Изменить</b-button>
                    <b-button @click="btnCancel()" class="mt-2 mx-1" variant="secondary">Отмена</b-button>
                </b-row>
            </b-col>
        </b-row>
    </b-modal>
</template>
<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
    components: {
        ToastificationContent,
    },
    props: ['id'],
    data(){
        return {
            country: {},
            submitButtonDisabled: false,
        }
    },
    mounted(){
        
    },
    methods: {
        editCountry(){
            this.submitButtonDisabled = true
            this.$http
                .patch(`addresses/countries/${this.id.id}`, this.country)
                .then(res => {
                    this.btnCancel()
                    // this.$bvModal.hide('countryModalEdit')
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Успех!',
                        icon: 'CheckIcon',
                        variant: 'success',
                        text: `Страна успешно изменена!`,
                        },
                    })
                    this.$emit('refresh')
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err.response.data.errors,
                        },
                    })
                })
                .finally(()=>{
                    this.submitButtonDisabled = false
                })
        },
        opened(){
            this.$http
                .get(`addresses/countries/${this.id.id}/edit`)
                .then(res => {
                    this.country = res.data
            })
        },
        btnCancel(){
        this.$bvModal.hide('countryModalEdit')

        }
    }
}
</script>